import { Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import {
  CarIcon,
  HandHoldingHeartIcon,
  HandsHelpingIcon,
  ListIcon,
  PersonIcon,
  PoliceCarFill,
  UserSearchFill,
  FileAltIcon,
  SupportIcon,
} from '../../../styles/icons';
import { Button, Container } from './styles';

const Favorites: React.FC = () => {
  const history = useHistory();
  const { user, iCan } = useAuth();

  return (
    <Container>
      <Grid container spacing={2}>

        {iCan('c-orders') && (
          <>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                type="button"
                onClick={() => history.push('/order/vehicle')}
              >
                <CarIcon size={32} />
                Consultar Veículo
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                type="button"
                onClick={() => history.push('/order/person')}
              >
                <PersonIcon size={32} />
                Consultar Pessoa
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                type="button"
                onClick={() => history.push('/orders/latest')}
              >
                <ListIcon size={32} />
                Consultas Recentes
              </Button>
            </Grid>
          </>
        )}

        {user?.is_intelligence && (
          <Grid item xs={12} sm={6} md={4} className="displayNone">
            <Button
              type="button"
              onClick={() => history.push('/orders/awaiting')}
            >
              <ListIcon size={32} />
              Fechar Consultas
            </Button>
          </Grid>
        )}

        {iCan('c-proximity') && (
          <Grid item xs={12} sm={6} md={4}>
            <Button
              type="button"
              onClick={() => history.push('/order/proximity')}
            >
              <HandsHelpingIcon size={32} />
              Abordagem de Proximidade
            </Button>
          </Grid>
        )}

        {iCan('c-social') && (
          <Grid item xs={12} sm={6} md={4}>
            <Button type="button" onClick={() => history.push('/social')}>
              <HandHoldingHeartIcon size={32} />
              Abordagem Social
            </Button>
          </Grid>
        )}
        {iCan('c-bdtd') && (
          <Grid item xs={12} sm={6} md={4}>
            <Button type="button" onClick={() => history.push('/bdtd')}>
              <PoliceCarFill size={32} />
              BDTD
            </Button>
          </Grid>
        )}
        {iCan('c-sinalid') && (
          <Grid item xs={12} sm={6} md={4}>
            <Button type="button" onClick={() => history.push('/sinalid')}>
              <UserSearchFill size={32} />
              Sinalid
            </Button>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={4}>
          <Button type="button" onClick={() => history.push('/RO')}>
            <FileAltIcon size={32} />
            RO-SP
          </Button>
        </Grid>

        {iCan('c-technician') && (
          <Grid item xs={12} sm={6} md={4}>
            <Button type="button" onClick={() => history.push('/support/awaiting')}>
              <SupportIcon size={32} />
              Suporte L8
            </Button>
          </Grid>
        )}
        {iCan('r-technician') && (
          <Grid item xs={12} sm={6} md={4}>
            <Button type="button" onClick={() => history.push('/support/technician')}>
              <SupportIcon size={32} />
              Técnico L8
            </Button>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Favorites;
