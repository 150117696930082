import React from 'react';
import { Switch } from 'react-router-dom';
import CadPreview from 'pages/CadPreview';

import { Route, RoutePrivate } from './Route';
import Assessments from '../pages/Assessments';
import Forgot from '../pages/Forgot';
import Home from '../pages/Home';
import Reset from '../pages/Reset';
import SignIn from '../pages/SignIn';

import Dashboard from '../pages/Dashboard';

import Sectors from '../pages/Sectors';
import SectorForm from '../pages/Sectors/Form';

import Polls from '../pages/Poll';
import PollForm from '../pages/Poll/Form';

import Services from '../pages/Services';
import ServiceCsvUpload from '../pages/Services/CsvUpload';
import ServiceStore from '../pages/Services/Store';
import ServiceUpdate from '../pages/Services/Update';

import BodyCam from '../pages/BodyCam';
import Pmerj from '../pages/Pmerj';
import SupportList from '../pages/Support';
import SupportForm from '../pages/Support/Form';
import SupportAwaiting from '../pages/Support/Awaiting';
import SupportAwaitingList from '../pages/Support/Awaiting/List';
import SupportDetail from '../pages/Support/Detail';
import SupportTechnician from '../pages/Support/Technician';
import SupportProcess from '../pages/Support/Process';
import SupportObserver from '../pages/Support/Observe';
import SupportTechnicianProcess from '../pages/Support/Technician/Process';

import OrderLasts from '../pages/Orders/Latest';
import OrderMe from '../pages/Orders/Me';
import OrderDetails from '../pages/Orders/Me/Detail';
import OrderAlert from '../pages/Orders/Me/Alert';
import OrderOpen from '../pages/Orders/Open';

import OrderSelect from '../pages/Orders/SelectOption';
import OrderExtends from '../pages/Orders/Me/Extends';

import OrderAnswered from '../pages/Orders/Answered';
import OrderAnsweredDetail from '../pages/Orders/Answered/Detail';
import OrderAwaiting from '../pages/Orders/Awaiting';
import OrderProcess from '../pages/Orders/Process';

import OrderDetranDetails from '../pages/Orders/Person/Detran/Detail';

import OrderProximityDetail from '../pages/Orders/Proximity/Detail';
import OrderProximityList from '../pages/Orders/Proximity/List';

import CeperjDataStudio from '../pages/Ceperj/DataStudio';
import CeperjFormSupervision from '../pages/Ceperj/FormSupervision';

import Ceperj from '../pages/Ceperj';
import CeperjDetail from '../pages/Ceperj/Detail';
import CeperjSupervisionDetail from '../pages/Ceperj/DetailSupervision';
import CeperjList from '../pages/Ceperj/List';
import CeperjSupervisionList from '../pages/Ceperj/ListSupervision';

import Bdtd from '../pages/Bdtd';
import BdtdDetail from '../pages/Bdtd/Detail';
import BdtdEdit from '../pages/Bdtd/Edit';
import BdtdList from '../pages/Bdtd/List';

import Sinalid from '../pages/Sinalid';
import SinalidDetail from '../pages/Sinalid/Detail';

import RcolsList from '../pages/LeiSeca/Rcols';
import RcolsDetail from '../pages/LeiSeca/Rcols/Detail';
import RcolsEdit from '../pages/LeiSeca/Rcols/Edit';
import Rcols from '../pages/LeiSeca/Rcols/Form';

import ReducolsList from '../pages/LeiSeca/Reducols';
import ReducolsDetail from '../pages/LeiSeca/Reducols/Detail';
import ReducolsEdit from '../pages/LeiSeca/Reducols/Edit';
import Reducols from '../pages/LeiSeca/Reducols/Form';

import LeiSeca from '../pages/LeiSeca/AdmFiscal';
import LeiSecaDashboard from '../pages/LeiSeca/AdmFiscal/Dashboard';
import LeiSecaDetail from '../pages/LeiSeca/Detail';

import LeiSecaEducacao from '../pages/LeiSeca/AdmEducacao';
import LeiSecaDashboardEducacao from '../pages/LeiSeca/AdmEducacao/Dashboard';
import LeiSecaDetailEducacao from '../pages/LeiSeca/AdmEducacao/Detail';

import MrcolsDetail from '../pages/LeiSeca/Mrcols/Detail';
import MrcolsEdit from '../pages/LeiSeca/Mrcols/Edit';
import Mrcols from '../pages/LeiSeca/Mrcols/Form';

import BdtdListSupervisor from '../pages/Bdtd/Supervisor';
import BdtdSupervisor from '../pages/Bdtd/Supervisor/ClosedSupervisor';
import BdtdDetailSupervisor from '../pages/Bdtd/Supervisor/DetailSupervisor';

import Fleet from '../pages/Fleet';
import FleetForm from '../pages/Fleet/Form';

import Maintenance from '../pages/Maintenance';
import MaintenanceForm from '../pages/Maintenance/Form';
import MaintenanceList from '../pages/Maintenance/List';
import Company from '../pages/Company';
import CompanyProcess from '../pages/Company/Process';
import CompanyDetail from '../pages/Company/Detail';
import CompanyHistory from '../pages/Company/History';

import Gases from '../pages/Gases';
import GasesForm from '../pages/Gases/Form';

import Makes from '../pages/Makes';
import MakesForm from '../pages/Makes/Form';
import ModelsForm from '../pages/Makes/FormModel';

import RO from '../pages/RO';
import ROMenu from '../pages/RO/Menu';
import ROStep from '../pages/RO/Step';
import ROList from '../pages/RO/List';
import ROListFisc from '../pages/RO/List/ListFisc';
import RODetail from '../pages/RO/Detail';
import RODashboard from '../pages/RO/Dashboard';

import CarInvolved from '../pages/RO/CarInvolved';
import CarInvolvedUpdate from '../pages/RO/CarInvolved/Update';
import CarInvolvedStorage from '../pages/RO/CarInvolved/Storage';

import Police from '../pages/RO/Police';
import PoliceUpdate from '../pages/RO/Police/Update';
import PoliceStorage from '../pages/RO/Police/Storage';

import Involveds from '../pages/RO/Involved';
import InvolvedStorage from '../pages/RO/Involved/Storage';
import InvolvedUpdate from '../pages/RO/Involved/Update';
// import InvolvedDetail from '../pages/RO/Involved/Detail';
import OccurrenceStorage from '../pages/RO/Occurrence/Storage';
import OccurrenceUpdate from '../pages/RO/Occurrence/Update';

import Seizures from '../pages/RO/Seizures';
import SeizuresMenu from '../pages/RO/Seizures/Menu';
import SeizuresUpdate from '../pages/RO/Seizures/Update';
import SeizuresStorage from '../pages/RO/Seizures/Storage';

import SocialList from '../pages/Social/List';
import Social from '../pages/Social';
import SocialDetail from '../pages/Social/Detail';

import Statistic from '../pages/Statistic';
import Extraction from '../pages/Statistic/Extraction';

import ClosingForms from '../pages/ClosingForms';
import ClosingFormsDetail from '../pages/ClosingForms/Detail';
import ClosingFormsForm from '../pages/ClosingForms/Form';

import Managements from '../pages/Managements';
import ManagementForm from '../pages/Managements/Form';
import ManagementUsers from '../pages/Managements/Users';

import Allocation from '../pages/Allocation';
import People from '../pages/People';
import PersonForm from '../pages/People/Form';

import Vehicles from '../pages/Vehicles';
import VehicleForm from '../pages/Vehicles/Form';

import User from '../pages/User';
import UserPassword from '../pages/User/ChangePassword';
import UserForm from '../pages/User/Form';
import UserProfile from '../pages/User/MeProfile';

import Acl from '../pages/System/Acl';
import AclRules from '../pages/System/Acl/Roles/Form';
import SystemCproeisName from '../pages/System/CproeisName';
import FixAnswer from '../pages/System/FixAnswer';
import Settings from '../pages/System/Settings';
import Pops from '../pages/Pops';

import ClassRoom from '../pages/ClassRoom';
import Assessment from '../pages/ClassRoom/Assessment';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Home} />

    <Route title="Esqueci minha senha" path="/forgot" component={Forgot} />
    <Route title="Cadastro" path="/preview" component={CadPreview} />
    <Route title="Pesquisa" path="/assessments/:id?" component={Assessments} />

    <RoutePrivate noPrivate title="Login" path="/signin/:action?" component={SignIn} />
    <RoutePrivate title="Criar nova senha" noPrivate path="/reset/:token?" component={Reset} />

    <RoutePrivate title="Selecionar posto de serviço" path="/allocation" component={Allocation} />

    <RoutePrivate title="Dashboard" path="/dashboard" component={Dashboard} />

    <RoutePrivate title="Setores" path="/sectors" can="r-sector" component={Sectors} />
    <RoutePrivate title="Setor" path="/sector/:id?" can="c-sector" component={SectorForm} />

    <RoutePrivate title="Serviços" exact path="/services" can="r-service" component={Services} />
    <RoutePrivate title="Incluir Serviços" path="/services/store" can="c-service" component={ServiceStore} />
    <RoutePrivate title="Editar Serviços" path="/services/update/:id" can="u-service" component={ServiceUpdate} />
    <RoutePrivate title="Importar Serviços" path="/services/csv-upload" is="admin" component={ServiceCsvUpload} />

    <RoutePrivate title="Listagem Apuração" path="/polls" can="r-poll" component={Polls} />
    <RoutePrivate title="Apuração" path="/poll/:id?" can="c-poll" component={PollForm} />

    <RoutePrivate title="Minhas Consultas" path="/orders/me" can="r-orders" component={OrderMe} />
    <RoutePrivate title="Minhas Consultas Recentes" exact path="/orders/latest" can="r-orders" component={OrderLasts} />
    <RoutePrivate title="Selection Consulta" path="/order/selectoption" exact can="c-orders" component={OrderSelect} />
    <RoutePrivate title="Consultar" exact path="/order/:type" can="c-orders" component={OrderOpen} />
    <RoutePrivate title="Detalhe da Consulta" path="/order/detail/:id" can="r-orders" component={OrderDetails} />

    <RoutePrivate title="Alerta de Roubo-Furto" path="/order/alert/:id" can="r-orders" component={OrderAlert} />

    <RoutePrivate title="Detalhe Detran" path="/order/detran/detail/:id" can="r-orders" component={OrderDetranDetails} />

    <RoutePrivate title="BodyCam" path="/bodycam" can="c-orders" component={BodyCam} />
    <RoutePrivate title="Pmerj" path="/pmerj" can="c-orders" component={Pmerj} />

    <RoutePrivate title="Pops" path="/pops" exact can="r-orders" component={Pops} />

    <RoutePrivate title="Novo Chamado" path="/support/create" exact can="r-support" component={SupportForm} />
    <RoutePrivate title="Suporte L8" path="/support/awaiting" exact can="c-technician" component={SupportAwaiting} />
    <RoutePrivate title="Suporte L8 listagem" path="/support/awaiting/list" exact can="c-technician" component={SupportAwaitingList} />
    <RoutePrivate title="Suporte L8" path="/supports/:id" exact can="c-technician" component={SupportDetail} />
    <RoutePrivate title="Suporte L8-Técnico" path="/support/technician" exact can="r-technician" component={SupportTechnician} />
    <RoutePrivate title="Suporte L8-Técnico" path="/support/technician/:id" exact can="r-technician" component={SupportTechnicianProcess} />

    <RoutePrivate title="Suporte L8 Observer" path="/support/observer" exact can="c-technician" component={SupportObserver} />

    <RoutePrivate title="Atendimento Suporte" path="/support/process/:id" exact can="c-technician" component={SupportProcess} />
    <RoutePrivate title="Listagem Suporte" path="/support" can="r-support" component={SupportList} />

    <RoutePrivate title="Detalhe da Consulta" exact path="/order/extends/:id" can="r-orders" component={OrderExtends} />

    <RoutePrivate title="Lista Proximidade" exact path="/orders/proximity/:type" can="r-proximity" component={OrderProximityList} />
    <RoutePrivate title="Detalhe Proximidade" path="/orders/proximity/detail/:id" can="c-proximity" component={OrderProximityDetail} />

    <RoutePrivate title="Ceperj" exact path="/ceperj/datastudio/" can="r-supervisor" component={CeperjDataStudio} />
    <RoutePrivate title="Ceperj" exact path="/ceperj/supervisao/" can="c-supervisor" component={CeperjFormSupervision} />
    <RoutePrivate title="Ceperj" exact path="/ceperj/supervisao/detail/:id" can="r-supervisor" component={CeperjSupervisionDetail} />
    <RoutePrivate title="Ceperj" exact path="/ceperj/supervisao/:type" can="r-supervisor" component={CeperjSupervisionList} />

    <RoutePrivate title="Ceperj" path="/ceperj/:id?" can="c-search" component={Ceperj} />
    <RoutePrivate title="Ceperj" exact path="/ceperjs/:type" can="r-search" component={CeperjList} />
    <RoutePrivate title="Ceperj" path="/ceperjs/detail/:id" can="r-search" component={CeperjDetail} />

    <RoutePrivate title="Lei Seca" exact path="/leiseca" can="r-leiseca" component={LeiSeca} />
    <RoutePrivate title="Lei Seca" exact path="/leiseca/dashboard" can="r-leiseca" component={LeiSecaDashboard} />
    <RoutePrivate title="Lei Seca" exact path="/leiseca/detail/:id" can="r-leiseca" component={LeiSecaDetail} />

    <RoutePrivate title="Lei Seca Educação" exact path="/leiseca-educacao" can="r-leiseca" component={LeiSecaEducacao} />
    <RoutePrivate title="Lei Seca Educação" exact path="/leiseca/dashboard-educacao" can="r-leiseca" component={LeiSecaDashboardEducacao} />
    <RoutePrivate title="Lei Seca Educação" exact path="/leiseca/detail-educacao/:id" can="r-leiseca" component={LeiSecaDetailEducacao} />

    <RoutePrivate title="Rcols" exact path="/rcol/:id?" can="c-rcols" component={Rcols} />
    <RoutePrivate title="Rcols" exact path="/rcol/edit/:id" can="c-rcols" component={RcolsEdit} />
    <RoutePrivate title="Rcols" exact path="/rcols/detail/:id" can="c-rcols" component={RcolsDetail} />
    <RoutePrivate title="Rcols" path="/rcols" can="c-rcols" component={RcolsList} />

    <RoutePrivate title="Reducols" exact path="/reducol/:id?" can="c-reducols" component={Reducols} />
    <RoutePrivate title="Reducols" exact path="/reducol/edit/:id" can="c-reducols" component={ReducolsEdit} />
    <RoutePrivate title="Reducols" exact path="/reducols/detail/:id" can="c-reducols" component={ReducolsDetail} />
    <RoutePrivate title="Reducols" path="/reducols" can="c-reducols" component={ReducolsList} />

    <RoutePrivate title="Mrcols" exact path="/mrcols" can="c-mrcols" component={Mrcols} />
    <RoutePrivate title="Mrcols" exact path="/mrcols/edit/:id" can="c-mrcols" component={MrcolsEdit} />
    <RoutePrivate title="Mrcols" exact path="/mrcols/detail/:id" can="c-mrcols" component={MrcolsDetail} />

    <RoutePrivate title="Sala de aula" exact path="/classrooms/:slug?" can="r-orders" component={ClassRoom} />
    <RoutePrivate title="Sala de aula Avaliaçao" path="/assessmentv/:id" can="r-orders" component={Assessment} />

    <RoutePrivate title="Bdtd" exact path="/bdtd" can="c-bdtd" component={Bdtd} />
    <RoutePrivate title="Bdtd" path="/bdtd/edit/:id" can="c-bdtd" component={BdtdEdit} />
    <RoutePrivate title="Bdtd" path="/bdtd/:id" can="c-bdtd" component={BdtdDetail} />
    <RoutePrivate title="Bdtd" path="/bdtds" can="c-bdtd" component={BdtdList} />

    <RoutePrivate title="Sinalid" exact path="/sinalid" can="c-sinalid" component={Sinalid} />
    <RoutePrivate title="Sinalid Detalhes" path="/sinalid/detail/:id" can="c-sinalid" component={SinalidDetail} />

    <RoutePrivate title="Bdtd Fiscal" exact path="/sbdtd/close/:id" can="s-bdtd" component={BdtdSupervisor} />
    <RoutePrivate title="Bdtd Fiscal" exact path="/sbdtds/supervisor" can="s-bdtd" component={BdtdListSupervisor} />
    <RoutePrivate title="Bdtd Fiscal" path="/sbdtd/:id" can="s-bdtd" component={BdtdDetailSupervisor} />

    <RoutePrivate title="Viatura" exact path="/fleet/:id?" can="c-fleets" component={FleetForm} />
    <RoutePrivate title="Viaturas" path="/fleets" can="c-fleets" component={Fleet} />

    <RoutePrivate title="Manutenção" path="/maintenances" can="c-fleets" component={Maintenance} />
    <RoutePrivate title="Manutenção" path="/maintenance/list" can="c-fleets" component={MaintenanceList} />
    <RoutePrivate title="Manutenção" path="/maintenance/:id?" can="c-fleets" component={MaintenanceForm} />

    <RoutePrivate title="Manutenção | Dashboard" path="/companys" can="c-fleets" component={Company} />
    <RoutePrivate title="Manutenção" path="/company/history" can="c-fleets" component={CompanyHistory} />
    <RoutePrivate title="Manutenção" path="/company/detail/:id?" can="c-fleets" component={CompanyDetail} />
    <RoutePrivate title="Manutenção | Processar" path="/company/:id?" can="c-fleets" component={CompanyProcess} />

    <RoutePrivate title="Posto de Combustível" exact path="/gas/:id?" can="c-gases" component={GasesForm} />
    <RoutePrivate title="Posto de Combustível" path="/gases" can="c-gases" component={Gases} />

    <RoutePrivate title="Marcas e Modelos" exact path="/make/:id?" can="c-fleets" component={MakesForm} />
    <RoutePrivate title="Marcas e Modelos" exact path="/model/:id?" can="c-fleets" component={ModelsForm} />
    <RoutePrivate title="Marcas e Modelos" path="/makes" can="c-fleets" component={Makes} />

    <RoutePrivate title="RO" exact path="/RO" can="c-rosp" component={RO} />
    <RoutePrivate title="RO" path="/RO/step/:step?" can="c-rosp" component={ROStep} />
    <RoutePrivate title="RO" exact path="/RO/list" can="c-rosp" component={ROList} />
    <RoutePrivate title="RO" exact path="/RO/list-fisc" can="c-RO" component={ROListFisc} />
    <RoutePrivate title="RO" exact path="/RO/dashboard" can="d-rosp" component={RODashboard} />
    <RoutePrivate title="RO" exact path="/RO/detail/:id/:id2?" can="c-rosp" component={RODetail} />
    <RoutePrivate title="RO" exact path="/RO/:id" can="c-rosp" component={ROMenu} />

    <RoutePrivate title="Ocorrência" exact path="/RO/occurrence/storage" can="c-rosp" component={OccurrenceStorage} />
    <RoutePrivate title="Ocorrência" exact path="/RO/occurrence/:id?" can="c-rosp" component={OccurrenceUpdate} />

    <RoutePrivate title="Véiculos Envolvido" exact path="/RO/carInvolved/show" can="c-rosp" component={CarInvolved} />
    <RoutePrivate title="Véiculos Envolvido" exact path="/RO/carInvolved/storage" can="c-rosp" component={CarInvolvedStorage} />
    <RoutePrivate title="Véiculos Envolvido" exact path="/RO/carInvolved/:id?" can="c-rosp" component={CarInvolvedUpdate} />

    <RoutePrivate title="Autoridade Policial" exact path="/RO/polices/show" can="c-rosp" component={Police} />
    <RoutePrivate title="Autoridade Policial" exact path="/RO/polices/storage" can="c-rosp" component={PoliceStorage} />
    <RoutePrivate title="Autoridade Policial" exact path="/RO/polices/:id?" can="c-rosp" component={PoliceUpdate} />

    <RoutePrivate title="Envolvidos" exact path="/RO/involveds/show" can="c-RO" component={Involveds} />
    <RoutePrivate title="Envolvidos" exact path="/RO/involved/storage" can="c-RO" component={InvolvedStorage} />
    <RoutePrivate title="Envolvidos" exact path="/RO/involved/:id?" can="c-RO" component={InvolvedUpdate} />

    <RoutePrivate title="Apreenções" exact path="/RO/seizures/show" can="c-rosp" component={Seizures} />
    <RoutePrivate title="Apreenções" exact path="/RO/seizures/storage/menu" can="c-rosp" component={SeizuresMenu} />
    <RoutePrivate title="Apreenções" exact path="/RO/seizures/:id?" can="c-rosp" component={SeizuresUpdate} />
    <RoutePrivate title="Apreenções" exact path="/RO/seizures/storage/:type" can="c-rosp" component={SeizuresStorage} />

    <RoutePrivate title="Sociais" exact path="/socials/:type" can="r-social" component={SocialList} />
    <RoutePrivate title="Social" path="/social/:id?" can="c-social" component={Social} />
    <RoutePrivate title="Social" path="/socials/detail/:id" can="r-social" component={SocialDetail} />

    <RoutePrivate title="Consultas não respondidas" exact path="/orders/awaiting" component={OrderAwaiting} />
    <RoutePrivate title="Responder Consulta" exact path="/order/process/:id" component={OrderProcess} />
    <RoutePrivate title="Consultas Respondidas" exact path="/orders/answered" component={OrderAnswered} />
    <RoutePrivate title="Detalhe da Resposta" exact path="/order/answered/detail/:id" component={OrderAnsweredDetail} />

    <RoutePrivate title="Estatística" exact path="/statistic" is="statistic" component={Statistic} />
    <RoutePrivate title="Exportar Consultas" path="/statistic/extraction" is="statistic" component={Extraction} />

    <RoutePrivate title="Formulário de encerramento" exact path="/closing-forms" can="r-register" component={ClosingForms} />
    <RoutePrivate title="Formulário de encerramento" path="/closing-forms/order/:id" can="c-register" component={ClosingFormsForm} />
    <RoutePrivate title="Formulário de encerramento" path="/closing-forms/detail/:id" can="r-register" component={ClosingFormsDetail} />

    <RoutePrivate title="Gerências" path="/managements" component={Managements} />
    <RoutePrivate title="Gerência" exact path="/management/:id?" component={ManagementForm} />
    <RoutePrivate title="Gerência Efetivo" exact path="/management/users/:id?" component={ManagementUsers} />

    <RoutePrivate title="Pessoa" path="/person/:id?" can="r-people" component={PersonForm} />
    <RoutePrivate title="Pessoas" path="/people" can="r-people" component={People} />

    <RoutePrivate title="Veículo" path="/vehicle/:id?" can="r-vehicle" component={VehicleForm} />
    <RoutePrivate title="Veículos" path="/vehicles" can="r-vehicle" component={Vehicles} />

    <RoutePrivate title="Usuários" path="/users" can="r-user" component={User} />
    <RoutePrivate title="Usuário" path="/user/:id?" can="u-user" component={UserForm} />
    <RoutePrivate title="Meus dados" path="/profile" component={UserProfile} />
    <RoutePrivate title="Alterar Senha" path="/password" component={UserPassword} />

    <RoutePrivate title="Acl" path="/acl" is="admin" component={Acl} />
    <RoutePrivate title="Acl Rules" path="/acl-rule/:id?" is="admin" component={AclRules} />
    <RoutePrivate title="Fix" path="/fix-answer" is="admin" component={FixAnswer} />
    <RoutePrivate title="Settings" path="/settings/:resource" exact is="admin" component={Settings} />
    <RoutePrivate title="Settings" path="/settings/:resource/:id" exact is="admin" component={Settings} />
    <RoutePrivate title="Cproeis Eventos" path="/settings/cproeis-name" is="admin" component={SystemCproeisName} />
  </Switch>
);

export default Routes;
